// rewrite of twitterAuthMixin.js
export const useTwitterAuth = () => {
  const config = useRuntimeConfig().public;
  const signIn = () => {
    postUser(`/auth/multi_twitter?origin=${getRedirectOrigin()}`);
  };
  const signOut = () => {
    postUser(`/auth/logout?origin=${window.location.origin}`);
  };
  const postUser = (path: string) => {
    const form = document.createElement("form");
    form.method = "post";
    form.action = `${config.BACKEND_URL}${path}`;

    document.body.appendChild(form);
    form.submit();
  };
  const getRedirectOrigin = () => {
    // redirect to dashboard except in the cases of checkout, archive upload and pricing page
    if (
      window.location.pathname === "/checkout" ||
      window.location.pathname === "/archive-upload" ||
      window.location.pathname === "/pricing"
    )
      return window.location.href;
    else return `${window.location.origin}/dashboard/search-and-delete`;
  };

  return {
    signIn,
    signOut,
  };
};
